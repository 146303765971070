<div class="fakebody row p-0 m-0 align-items-center">
  <div class="col p-0 m-0">
    <div class="box m-auto overflow-auto">
      <form (submit)="login()">
        <h2
          class="text-center"
          i18n="@@login"
        >Login</h2>

        <p
          *ngIf="this.message"
          class="alert alert-info"
        >
          {{this.message}}
        </p>
        <ng-container *ngIf="state == 'email'">
          <div class="inputBox">
            <input
              required="required"
              autocomplete="email"
              type="email"
              id="email"
              name="email"
              [(ngModel)]="email"
            >
            <span i18n="@@emailAddress">Email Adresse</span>
            <i></i>
          </div>
        </ng-container>
        <ng-container *ngIf="state == 'code'">
          <div i18n="@@enterAuthCode">Bitte geben Sie den Code aus Ihrer E-Mail oder Ihrer Authentifizierungs-App ein
          </div>
          <div class="inputBox">
            <input
              required="required"
              autocomplete="password"
              type="password"
              id="password"
              name="password"
              [(ngModel)]="code"
            >
            <span i18n="@@code">Code</span>
            <i></i>
          </div>
        </ng-container>
        <ng-container *ngIf="state == 'mandant'">


          <div
            class="mandant ro"
            [ngClass]="router.url.startsWith('/admin') ? 'bg-primary' : ''"
            (click)="router.navigate(['/admin'])"
            *ngIf="this.loginService.loggedInUser?.reflactadmin"
          >

            <div class="d-flex align-items-center px-3"><i class="ri-3x ri-shield-user-line"></i><b
                class="ms-2">Superadmin</b>
            </div>
          </div>

          <div
            class="mandant ro"
            *ngFor="let mandant of mandants"
            (click)="this.selectMandant(mandant)"
          >
            <div class="d-flex align-items-center px-3"><i class="ri-3x ri-building-line"></i><b
                class="ms-2">{{mandant.name}}</b></div>
          </div>
        </ng-container>
        <div class="text-center mt-3">
          <input
            *ngIf="state != 'mandant'"
            type="submit"
            class="btn btn-outline-primary"
            style="width: 100px;"
            i18n-value="@@next"
            value="Weiter"
          >
        </div>
      </form>
    </div>
  </div>
</div>